body {
  font-family: 'Roboto', sans-serif;
  background-color: #F4F4F4;
}
#video {
  z-index: -1;
  height: 100%;
  width: 100%;
  padding: none;
  position: absolute;
  left:50%;
  top: 50%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}
.datePicker {
  font-weight:200;
  font-size: 10pt;
  color:#333;
  text-align:center;
}
.footer {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}
.button {
  background-color:#1ea51e;
}
.center {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.prow {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.subheading {
  font-weight: 300;
}
.title {
  font-weight:bold;
  font-size: 18pt;
  color:'#000';
}
.textInput {
  max-width: 80px;
  text-align: center;
}
.homeTown {
  font-weight:300;
  color:'#ccc';
  font-size: 10px;
}
.timeAgo {
  font-weight:300;
  color:'#ccc';
  font-size: 10px;
}
.description {
  font-weight:300;
  color:'#666';
  font-size: 12px;
  align-items:center;
  justify-content:center;
  display: flex;
}
.gameDetail {
  font-weight:100;
  color:'#ccc';
  font-size: 14px;
}
.gameScore {
  font-weight:500;
  color:'#ccc';
  font-size: 24px;
  align-items:center;
  justify-content:center;
  display: flex;
  background-color:#ddd;
  border-radius:5px;
  padding:10px;
  min-width:70px;
}
.puttRow {
  font-weight:200;
  color:'#ccc';
  font-size: 10px;
}
.loading {
  font-weight:300;
  color:'#999';
  font-size: 18px;
}
.roi {
  font-weight:500;
  color:'#999';
  font-size: 30px;
}
.lbCard {
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
  justify-content: start;
  display: flex;
  flex-direction: column;
  width: 350px;
  min-height: 200px;
  margin-top:20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;

}
.lbIcon {
  width:60px;
  height:60px;
  border-radius: 30px;
  background-color: #F4F4F4;
  font-size:30px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  top: -25px;
  border-color: #fff;
  border-width: thick;
  border-style: solid;
}
.gameIcon {
  width:80px;
  height:80px;
  border-radius: 40px;
  background-color: #F4F4F4;
  font-size:30px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-color: #fff;
  border-width: thick;
  border-style: solid;
}
.lbTitle {
  font-weight:bold;
  font-size: 20pt;
  color:#333;
  position: relative;
  top: -15px;
}
.lbRow {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  background-color: #F4F4F4;
  padding:10px;
  margin-bottom: 5px;
  border-radius: 20px;
  padding: 15px;
}
.lbRowSelected {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  background-color: #F4F4F4;
  padding:10px;
  margin-bottom: 5px;
  border-radius: 20px;
  padding: 15px;
  border-color: #1ea51e;
  border-width: thick;
  border-style: solid;
}
.lbDisplayName {
  font-weight:bold;
  font-size: 14pt;
  color:#000;
  justify-content: start;
  align-items: start;
  width:150px;
  display: flex;
}
.lbHometown {
  font-weight:500;
  font-size: 10pt;
  color:#333;
  justify-content: start;
  align-items: start;

  display: flex;
}
.lbPlace {
  font-weight:300;
  font-size: 10pt;
  color:#000;
  width:30px;
}
.lbScore {
  font-weight:500;
  font-size: 16px;
  color:#fff;
  width:50px;
  height:30px;
  border-radius: 15px;
  background-color: #1ea51e;
  justify-content: center;
  align-items: center;
  display: flex;
}
.qrCode {
  display:flex;
  justify-content:center;
  padding:20px;
}
.avatar {
  display: flex;
  width: 40px;
  height:40px;
  font-weight:100;
  font-size: 16pt;
  color:#ccc;
  background-color:#FFF;
  border-radius:20px;
  align-items:center;
  justify-content:center;
  margin-right:5px;
}
.ballPath {
  align-items:center;
  justify-content:center;
  display: flex;
  height:220px;
  width:320px;
  border-radius:5px;
}
.puttLocation {
  font-weight:300;
  color:'#333';
  font-size: 14px;
  align-items:center;
  justify-content:center;
  display: flex;
}
